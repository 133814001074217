/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    StitchedPunksNFT,
    StitchedPunksNFT__factory,
    StitchedPunksShop,
    StitchedPunksShop__factory,
} from '../../contracts/artifacts/types';
import Layout from '../components/Layout';
import { STITCHEDPUNKSNFT_CONTRACT, STITCHEDPUNKSSHOP_CONTRACT } from '../web3/BlockchainUtils';

export const DebugDashboard = (): JSX.Element => {
    const web3 = useWeb3React();
    const [currentPrice, setCurrentPrice] = useState('?');
    const [newPrice, setNewPrice] = useState('?');
    const [currentEarnings, setCurrentEarnings] = useState('?');
    const [newOrderStatusPunkId, setNewOrderStatusPunkId] = useState('?');
    const [newOrderStatus, setNewOrderStatus] = useState('?');
    const [mintNftPunkId, setMintNftPunkId] = useState('?');
    const [mintNftReceiverAddress, setMintNftReceiverAddress] = useState('?');

    const fetchPrice = useCallback(async () => {
        if (web3.library === undefined || !web3.active) {
            return;
        }

        try {
            const shop: StitchedPunksShop = StitchedPunksShop__factory.connect(
                STITCHEDPUNKSSHOP_CONTRACT,
                web3.library,
            );

            const currentPrice = await shop.currentOrderPrice();
            console.log('currentPrice:', currentPrice);
            setCurrentPrice(ethers.utils.formatUnits(currentPrice, 'ether'));
        } catch (err) {
            console.log('error ordering:', err);
        }
    }, [setCurrentPrice, web3.active, web3.library]);

    const fetchCurrentEarnings = useCallback(async () => {
        if (web3.library === undefined || !web3.active) {
            return;
        }

        try {
            const shop: StitchedPunksShop = StitchedPunksShop__factory.connect(
                STITCHEDPUNKSSHOP_CONTRACT,
                web3.library,
            );

            const earnings = await web3.library.getBalance(shop.address);
            console.log('earnings:', earnings);
            setCurrentEarnings(ethers.utils.formatUnits(earnings, 'ether'));
        } catch (err) {
            console.log('error ordering:', err);
        }
    }, [web3.active, web3.library]);

    useEffect(() => {
        fetchPrice();
        fetchCurrentEarnings();
    }, [fetchPrice, fetchCurrentEarnings]);

    const changePrice = useCallback(async () => {
        if (web3.library === undefined || !web3.active) {
            return;
        }

        try {
            const signer = web3.library.getSigner();

            const shop: StitchedPunksShop = StitchedPunksShop__factory.connect(STITCHEDPUNKSSHOP_CONTRACT, signer);

            const changePriceTx = await shop.setOrderPrice(ethers.utils.parseEther(newPrice));

            console.log('waiting for TX');

            const txReceipt = await changePriceTx.wait();

            console.log('TX done in block' + txReceipt.blockNumber);

            await fetchPrice();
        } catch (err) {
            console.log('error changing price:', err);
        }
    }, [web3.active, web3.library, fetchPrice, newPrice]);

    const withdrawEarnings = useCallback(async () => {
        if (web3.library === undefined || !web3.active) {
            return;
        }

        try {
            const signer = web3.library.getSigner();

            const shop: StitchedPunksShop = StitchedPunksShop__factory.connect(STITCHEDPUNKSSHOP_CONTRACT, signer);

            const withdrawTx = await shop.withdraw();

            console.log('waiting for TX');

            const txReceipt = await withdrawTx.wait();

            console.log('TX done in block' + txReceipt.blockNumber);
        } catch (err) {
            console.log('error ordering:', err);
        }
    }, [web3.active, web3.library]);

    const updateOrderStatus = useCallback(async () => {
        if (web3.library === undefined || !web3.active) {
            return;
        }

        try {
            const signer = web3.library.getSigner();

            const shop: StitchedPunksShop = StitchedPunksShop__factory.connect(STITCHEDPUNKSSHOP_CONTRACT, signer);

            const updateStatusTx = await shop.updateOrderStatus(newOrderStatusPunkId, newOrderStatus);

            console.log('waiting for TX');

            const txReceipt = await updateStatusTx.wait();

            console.log('TX done in block' + txReceipt.blockNumber);

            await fetchPrice();
        } catch (err) {
            console.log('error updating order status:', err);
        }
    }, [web3.library, web3.active, newOrderStatusPunkId, newOrderStatus, fetchPrice]);

    const mintNft = useCallback(async () => {
        if (web3.library === undefined || !web3.active) {
            return;
        }

        try {
            const signer = web3.library.getSigner();

            const shop: StitchedPunksNFT = StitchedPunksNFT__factory.connect(STITCHEDPUNKSNFT_CONTRACT, signer);

            const mintTokenTx = await shop.mintToken(mintNftPunkId, mintNftReceiverAddress);

            console.log('waiting for TX');

            const txReceipt = await mintTokenTx.wait();

            console.log('TX done in block' + txReceipt.blockNumber);

            await fetchPrice();
        } catch (err) {
            console.log('error minting token:', err);
        }
    }, [web3.library, web3.active, mintNftPunkId, mintNftReceiverAddress, fetchPrice]);

    return (
        <div>
            <ul className="actions">
                <ul className="actions">
                    <li>Withdraw Earnings:</li>
                    <li>Current Earnings: {currentEarnings} ETH</li>
                    <li>
                        <a onClick={() => withdrawEarnings()} role="button" className="button small">
                            withdraw()
                        </a>
                    </li>
                </ul>
                <li>Current Price: {currentPrice}</li>
                <li>New Price:</li>
                <li>
                    <input
                        type="text"
                        name="current-price"
                        id="current-price"
                        defaultValue=""
                        placeholder=""
                        onChange={(e) => setNewPrice(e.target.value)}
                    />
                </li>
                <li>
                    <a onClick={() => changePrice()} role="button" className="button small">
                        Change Price
                    </a>
                </li>
            </ul>
            <ul className="actions">
                <li>Update Order Status</li>
                <li>Punk Index:</li>
                <li>
                    <input
                        type="text"
                        name="punk-order-to-update"
                        id="punk-order-to-update"
                        defaultValue=""
                        placeholder=""
                        onChange={(e) => setNewOrderStatusPunkId(e.target.value)}
                    />
                </li>
                <li>New Status:</li>
                <li>
                    <input
                        type="text"
                        name="punk-order-new-status"
                        id="punk-order-new-status"
                        defaultValue=""
                        placeholder=""
                        onChange={(e) => setNewOrderStatus(e.target.value)}
                    />
                </li>
                <li>
                    <a onClick={() => updateOrderStatus()} role="button" className="button small">
                        Update Status
                    </a>
                </li>
            </ul>
            <ul className="actions">
                <li>Mint NFT</li>
                <li>Punk Index:</li>
                <li>
                    <input
                        type="text"
                        name="punk-nft-to-mint"
                        id="punk-nft-to-mint"
                        defaultValue=""
                        placeholder=""
                        onChange={(e) => setMintNftPunkId(e.target.value)}
                    />
                </li>
                <li>Receiver Address:</li>
                <li>
                    <input
                        type="text"
                        name="punk-nft-to-mint-receiver"
                        id="punk-nft-to-mint-receiver"
                        defaultValue=""
                        placeholder=""
                        onChange={(e) => setMintNftReceiverAddress(e.target.value)}
                    />
                </li>
                <li>
                    <a onClick={() => mintNft()} role="button" className="button small">
                        Mint NFT
                    </a>
                </li>
            </ul>
        </div>
    );
};

export const OrderEventsListener = (): JSX.Element => {
    const web3 = useWeb3React();
    const [debugOutput, setDebugOutput] = useState('');

    const startListening = useCallback(async () => {
        if (web3.library === undefined || !web3.active) {
            return;
        }

        try {
            const shop: StitchedPunksShop = StitchedPunksShop__factory.connect(
                STITCHEDPUNKSSHOP_CONTRACT,
                web3.library,
            );

            shop.on('OrderCreated', (punkId, ownerAddress) => {
                setDebugOutput('OrderCreated: ' + punkId + ' from ' + ownerAddress + '\n' + debugOutput);
                console.log('OrderCreated for ' + punkId + ' from ' + ownerAddress);
            });
        } catch (err) {
            console.log('error ordering:', err);
        }
    }, [debugOutput, web3.active, web3.library]);

    useEffect(() => {
        startListening();
    }, [startListening]);

    return (
        <div>
            <p>Events:</p>
            <pre>{debugOutput}</pre>
        </div>
    );
};

export const OrderEventsFetcher = (): JSX.Element => {
    const web3 = useWeb3React();

    const startListening = useCallback(async () => {
        if (web3.library === undefined || !web3.active) {
            return;
        }

        try {
            const shop: StitchedPunksShop = StitchedPunksShop__factory.connect(
                STITCHEDPUNKSSHOP_CONTRACT,
                web3.library,
            );

            const filter = shop.filters.OrderCreated(null, null);
            const events = await shop.queryFilter(filter, 0, 'latest');

            events.forEach((ev) => {
                console.log('ev:', ev);
            });
        } catch (err) {
            console.log('error ordering:', err);
        }
    }, [web3.active, web3.library]);

    useEffect(() => {
        startListening();
    }, [startListening]);

    return (
        <div>
            <p>Events in console</p>
        </div>
    );
};

const DebugWeb3 = (): JSX.Element => {
    return (
        <Layout>
            <Helmet title="StitchedPunks – DevConsole" />

            <div id="main" className="alt">
                <section id="one">
                    <div className="inner">
                        <header className="major">
                            <h1>Debug</h1>
                        </header>
                        <div>
                            <DebugDashboard />
                            <OrderEventsListener />
                            <OrderEventsFetcher />
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
};

export default DebugWeb3;
